.popup{
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(204, 204, 204);
  box-shadow: rgba(0, 0, 0, 0.2) 2px 2px 3px;
  position: relative;
  transition: visibility 0s linear 0s, opacity 0.3s linear 0s;
  opacity: 1;
  visibility: visible;
  width: 300px;
  max-width: 100%;
  height: auto;
}

.imagesWrapper{

  display: grid;

  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 4px;

}