.captcha {
  background-color:#f9f9f9;
  border:2px solid #d3d3d3;
  border-radius:5px;
  color:#4c4a4b;
  display:flex;
  justify-content:space-around;
  align-items:center;
  width: 300px;
  max-width: 100%;
  font-size: 12px;
  margin: auto;
}


@media screen and (max-width: 500px) {
  .captcha {
    //flex-direction:column;
  }
  .text {
    //margin:.5em!important;
    //text-align:center;
  }
  .logo {
    //align-self: center!important;
  }
  .spinner {
    //margin:2em .5em .5em .5em!important;
  }
}

.text {
  //font-size:1.75em;
  //font-weight:500;
  margin-right:auto;
  white-space: nowrap;
}
.spinner {
  position:relative;
  width:2em;
  height:2em;
  display:flex;
  margin:2em 1em;
  align-items:center;
  justify-content:center;
}
input[type="checkbox"] { position: absolute; opacity: 0; z-index: -1; }
input[type="checkbox"]+.checkmark {
  display:inline-block;
  width:2em;
  height:2em;
  background-color:#fcfcfc;
  border:2.5px solid #c3c3c3;
  border-radius:3px;
  display:flex;
  justify-content:center;
  align-items:center;
  cursor: pointer;
}
input[type="checkbox"]+.checkmark span {
  content:'';
  position:relative;/*
	position:absolute;
	border-bottom:3px solid;
	border-right:3px solid;
	border-color:#029f56;*/
  margin-top:-3px;
  transform:rotate(45deg);
  width:.75em;
  height:1.2em;
  opacity:0;
}
input[type="checkbox"]+.checkmark>span:after {
  content:'';
  position:absolute;
  display:block;
  height:3px;
  bottom:0;left:0;
  background-color:#029f56;
}
input[type="checkbox"]+.checkmark>span:before {
  content:'';
  position:absolute;
  display:block;
  width:3px;
  bottom:0;right:0;
  background-color:#029f56;
}
input[type="checkbox"]:checked:not(.valid)+.checkmark {
  animation:2s spin  infinite;
}
input[type="checkbox"]:checked:not(.valid)+.checkmark>span {
  animation:1s fadein 1.9s infinite;
}
input[type="checkbox"]:checked:is(.valid)+.checkmark {
  animation:1s spin  1.2s forwards;
}
input[type="checkbox"]:checked:is(.valid)+.checkmark>span {
  animation:1s fadein 1.5s forwards;
}

input[type="checkbox"].valid:checked+.checkmark>span:after {animation:.3s bottomslide 1.2s forwards;}
input[type="checkbox"].valid:checked+.checkmark>span:before {animation:.5s rightslide 1.4s forwards;}
@keyframes fadein {
  0% {opacity:0;}
  100% {opacity:1;}
}
@keyframes bottomslide {
  0% {width:0;}
  100% {width:100%;}
}
@keyframes rightslide {
  0% {height:0;}
  100% {height:100%;}
}
.logo {
  display:flex;
  flex-direction:column;
  align-items:center;
  height:100%;
  align-self:flex-end;
  margin:0.5em 1em;
}
.logo img {
  height:2em;
  width:2em;
}
.logo p {
  color:#9d9ba7;
  margin:0;
  font-size:1em;
  font-weight:700;
  margin:.4em 0 .2em 0;
}
.logo small {
  color:#9d9ba7;
  margin:0;
  font-size:.8em;
}
@keyframes spin {
  //0% {
  //  width:0;
  //  height:0;
  //  border-width:6px;
  //}
  0% {
    width:2em;
    height:2em;
    border-radius:50%;
    border-width:1em;
    transform: rotate(0deg);
    border-color:rgb(199,218,245);
  }
  50% {
    width:2em;
    height:2em;
    border-radius:50%;
    border-width:4px;
    border-color:rgb(199,218,245);
    border-right-color:rgb(89,152,239);
  }
  70% {
    //border-width:4px;
    border-color:rgb(199,218,245);
    border-right-color:rgb(89,152,239);
  }
  90% {
    //border-width:4px;
  }
  100% {
    width:2em;
    height:2em;
    border-radius:50%;
    transform: rotate(720deg);
    //border-color:transparent;
  }
}
::selection {
  background-color:transparent;
  color:teal;
}
::-moz-selection {
  background-color:transparent;
  color:teal;
}