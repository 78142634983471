.layout-wrapper {

  width: 100%;
  height: 100%;
  background: #f5f7fb;
  position: relative;
  min-height: 100vh;
}

.layout-overlay {
  background: url("logo-blue.svg");
  position: absolute;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  left: 0;
  top: 0;
  background-size: 10vw;
  opacity: .15;
}

.main {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: relative;
  z-index: 1;
  min-height: 100vh;
}

.main-card {
  //box-shadow: 0 0 3px;
  //width: 50vw;
  //height: 50vh;
  background: #fff;
  max-width: 400px;

}